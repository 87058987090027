import React, {useState} from 'react';

const eyeImage = process.env.PUBLIC_URL + '/images/eye.svg';
const eyeDisabledImage = process.env.PUBLIC_URL + '/images/eye-disabled.svg'


export default ({value, onChange, label, placeholder, labelAddon = null, isPassword = false, error = null}) => {
  const [type, setType] = useState(isPassword ? 'password' : 'text');

  const toggleType = () => {
    setType(prev => prev === 'password' ? 'text': 'password');
  }

  return (
    <div className="input-wrapper">
      {
        labelAddon && (
          <div onClick={labelAddon.handler} className='label-addon'>{labelAddon.text}</div>
        )
      }
      {
        isPassword && (
          <div onClick={toggleType} className='eye-wrapper'>
            <img src={type === 'password' ? eyeImage : eyeDisabledImage} style={{width: '32px'}}/>
          </div>
        )
      }
      <label>{label}</label>
      <input value={value} onChange={onChange} {...error && {className: 'error'}} type={type} placeholder={placeholder}/>
      {
        error && (
          <div className='error-message'>
            {error}
          </div>
        )
      }
    </div>
  );
};
