import React from 'react';
import ModalBackdrop from './modal_base';
import Button from './button';

const image = process.env.PUBLIC_URL + '/images/email.svg';


export default ({onClose}) => (
  <ModalBackdrop onClose={onClose}>
    <img src={image} className='modal-image' />
    <div className='modal-header'>We've send you and email to reset your password</div>
    <div>If you're registered on the BE, you will receive an email</div>
    <div>with instructions on how to reset your password shortly.</div>
    <div className='modal-text-button-divider'/>
    <div className='modal-buttons-containers'>
      <Button
        text='Ok, got it'
        onClick={onClose}
      />
    </div>
  </ModalBackdrop>
);
