import React, {useState, useCallback, useEffect} from 'react';

import Input from '../components/input';
import Button from '../components/button';
import RecoverPasswordModal from '../components/recover_password_modal';
import RecoverPasswordSuccessModal from '../components/recover_password_success_modal';
import ResetPasswordModal from '../components/reset_password_modal';
import ResetPasswordSuccessModal from '../components/reset_password_success_modal';
import { isEmptyString } from '../util';
import config from '../config';

const BELogo = process.env.PUBLIC_URL + '/images/logo.svg';
const BEImage = process.env.PUBLIC_URL + '/images/be_image.png';

const MODAL_RECOVER_PASS_FORM = 'MODAL_RECOVER_PASS_FORM';
const MODAL_RECOVER_PASS_SUCCESS = 'MODAL_RECOVER_PASS_SUCCESS';
const MODAL_NEW_PASS_FORM = 'MODAL_NEW_PASS_FORM';
const MODAL_NEW_PASS_SUCCESS = 'MODAL_NEW_PASS_SUCCESS';

const getParam = param => () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get(param);
  return token || null;
};

const getResetToken = getParam('token');
const getSSOToken = getParam('Token');

export default () => {

  const [currentModal, setCurrentModal] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const hideModal = useCallback(() => setCurrentModal(null), []);
  const showModal = useCallback(modal => setCurrentModal(modal), []);

  const showRecoverSuccessModal = useCallback(() => setCurrentModal(MODAL_RECOVER_PASS_SUCCESS), []);
  const showResetSuccessModal = useCallback(() => setCurrentModal(MODAL_RECOVER_PASS_SUCCESS), []);

  const token = getResetToken();
  // in case if token not encoded properly
  const beBackOfficeToken = getSSOToken() || '';

  useEffect(() => {
    if (token) {
      setCurrentModal(MODAL_NEW_PASS_FORM);
    }
  }, [token]);

  useEffect(() => {
    if (beBackOfficeToken) {
      login();
    }
  }, [beBackOfficeToken]);

  useEffect(() => {
    if (error) {
      setError(null);
    }
  }, [username, password]);

  const recoverPassword = email => (
    fetch(`${config.apiURL}/exigo/password-recover`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email}),
    })
  );

  const resetPassword = newPassword => {
    return fetch(`${config.apiURL}/exigo/password-reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({newPassword, token}),
    });
  };

  const handleUserNameChange = useCallback(e => setUsername(e.target.value), []);
  const handlePasswordChange = useCallback(e => setPassword(e.target.value), []);

  const login = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await fetch(`${config.apiURL}/exigo/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({username, password, beBackOfficeToken}),
      });
      if (res.status !== 200) {
        setError('Invalid username or password');
        setLoading(false);
        return;
      }
      const json = await res.json();
      if (json.token && json.communityUrl) {
        window.location.href = `${json.communityUrl}/auth/sso?jwt=${json.token}`
      } else {
        setError('Invalid username or password');
      }
    } catch (e) {
      setError('Invalid username or password');
    }
    setLoading(false);
  };

  const forgotPassword = {
    text: 'Forgot password?',
    handler: () => showModal(MODAL_RECOVER_PASS_FORM),
  };

  return (
    <div className="page-container">
      <div className="login-block-wrapper">
        <div>
          <div>
            <img src={BELogo} className="logo"/>
          </div>
          <div className="prompt">
            Login to BE Community
          </div>
          <div className="form">
            <Input
              label='Email / Username'
              value={username}
              onChange={handleUserNameChange}
              placeholder='Your email or username'
            />
            <Input
              label='Password'
              labelAddon={forgotPassword}
              value={password}
              onChange={handlePasswordChange}
              error={error}
              placeholder='Your password'
              isPassword
            />
            <div style={{marginTop: '8px'}}></div>
            <Button
              text='Login'
              onClick={login}
              disabled={isEmptyString(username) || isEmptyString(password)}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div className='image-block'>
        <div className='image-block-text'>
          A global community for life transformation
        </div>
        <img src={BEImage} style={{width: '100%'}}/>
      </div>
      {
        currentModal === MODAL_RECOVER_PASS_FORM && (
          <RecoverPasswordModal
            onClose={hideModal}
            onSubmit={recoverPassword}
            showRecoverSuccessModal={showRecoverSuccessModal}
          />
        )
      }
      {
        currentModal === MODAL_RECOVER_PASS_SUCCESS && (
          <RecoverPasswordSuccessModal onClose={hideModal} />
        )
      }
      {
        currentModal === MODAL_NEW_PASS_FORM && (
          <ResetPasswordModal
            onClose={hideModal}
            onSubmit={resetPassword}
            showResetSuccessModal={showResetSuccessModal}
          />
        )
      }
      {
        currentModal === MODAL_NEW_PASS_SUCCESS && (
          <ResetPasswordSuccessModal onClose={hideModal} />
        )
      }
    </div>
  );
};
