import React from 'react';

const closeIcon = process.env.PUBLIC_URL + '/images/close.svg';


export default ({onClose, children}) => {

  const onClickModal = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div onClick={onClose} className='modal-backdrop'>
      <div className='modal-container' onClick={onClickModal}>
        <div onClick={onClose}  className='modal-close-icon-wrapper'>
          <img src={closeIcon} />
        </div>
        {children}
      </div>
    </div>
  );
};
