import React, {useState} from 'react';
import ModalBackdrop from './modal_base';
import Input from './input';
import Button from './button';
import {isEmptyString} from '../util';

const INITIAL_STATE = {password1: '', password2: ''};


export default ({onClose, onSubmit, showResetSuccessModal}) => {
  const [password, setPassword] = useState(INITIAL_STATE);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (field, v) => {
    if (error) {
      setError(null);
    }
    setPassword(prev => ({...prev, [field]: v}));
  };

  const handleSubmit = async () => {
    if (password.password1 !== password.password2) {
      setError('Passwords does not match');
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await onSubmit(password.password1);
      if (res.status !== 204) {
        setError('Invalid username or email');
        setLoading(false);
        return;
      }
      showResetSuccessModal();
    } catch (e) {
      setError('Error while resetting password.');
    }
    setLoading(false);
  }

  return (
    <ModalBackdrop onClose={onClose}>
      <div className='modal-header'>Create new password</div>
      <Input
        label='New password'
        value={password.password1}
        onChange={e => handleFieldChange('password1', e.target.value)}
        placeholder='Enter new password'
      />
      <Input
        label='Confirm new password'
        value={password.password2}
        onChange={e => handleFieldChange('password2', e.target.value)}
        error={error}
        placeholder='Confirm new password'
      />
      <div className='modal-buttons-containers'>
        <Button
          text='Back to Log in'
          onClick={onClose}
          outLine
        />
        <div className='buttons-container-divider' />
        <Button
          text='Reset password'
          onClick={handleSubmit}
          loading={loading}
          disabled={isEmptyString(password.password1) || isEmptyString(password.password2)}
        />
      </div>
    </ModalBackdrop>
  );
}
