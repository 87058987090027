import React from 'react';
import './style.css';

export default () => (
  <div className="lds-ellipsis">
    <div />
    <div />
    <div />
    <div />
  </div>
);