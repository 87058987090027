import React from 'react';
import cx from 'classnames';

import Loader from './loader';


export default ({text, onClick, outLine = false, disabled = false, loading = false}) => {
  const className = cx({
    'outline-button': outLine,
    'disabled-button': disabled,
  });
  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      {loading ? <Loader /> : text}
    </button>
  );
}
