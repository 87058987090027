import React from 'react';
import ModalBackdrop from './modal_base';
import Button from './button';


const image = process.env.PUBLIC_URL + '/images/lock.svg';

export default ({onClose}) => (
  <ModalBackdrop onClose={onClose}>
    <img src={image} className='modal-image' />
    <div className='modal-header'>Your password has been<br/>  successfully updated</div>
    <div className='modal-text-button-divider'/>
    <div className='modal-buttons-containers'>
      <Button
        text='Ok, got it'
        onClick={onClose}
      />
    </div>
  </ModalBackdrop>
);
