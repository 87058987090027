import React, {useState} from 'react';
import ModalBackdrop from './modal_base';
import Input from './input';
import Button from './button';
import {isEmptyString} from '../util';


export default ({onClose, onSubmit, showRecoverSuccessModal}) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);

  const handleEmailChange = e => {
    if (emailError) {
      setEmailError(null);
    }
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await onSubmit(email);
      if (res.status !== 204) {
        setLoading(false);
        setEmailError('Invalid username or email');
        return;
      }
      showRecoverSuccessModal();
    } catch (e) {
      setEmailError('Invalid username or email');
    }
    setLoading(false);
  };

  return (
    <ModalBackdrop onClose={onClose}>
      <div className='modal-header'>Forgot password?</div>
      <div>We will email you a link that will allow you to reset your</div>
      <div>password. Note: it may take few minutes</div>
      <div className="modal-inputs-wrapper">
        <Input
          label='Email'
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          placeholder='Your email'
        />
      </div>
      <div className='modal-buttons-containers'>
        <Button
          text='Back to Log In'
          onClick={onClose}
          outLine
        />
        <div className='buttons-container-divider' />
        <Button
          text='Reset password'
          onClick={handleSubmit}
          loading={loading}
          disabled={isEmptyString(email)}
        />
      </div>
    </ModalBackdrop>
  );
}
